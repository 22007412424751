import logo from "./logo.svg";
import "./App.css";
import { NumericFormat } from "react-number-format";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useState } from "react";

function App() {
  const [value, setValue] = useState(0);

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          console.log("a");
        }}
      >
        <div>
          1
          <NumericFormat className={`currency-input-alternate search-wrapper searchWrapper currencyInput`} allowLeadingZeros prefix="R$ " decimalScale={2} decimalSeparator="," thousandSeparator="." enabl />
        </div>

        <div>
          2
          <input value={value} type="number" />
        </div>

        <div>
          3
          <input type="number" inputMode="decimal" />
        </div>

        <div>
          4
          <input type="text" />
        </div>

        <div>
          5
          <input type="text" inputMode="decimal" pattern="\d+((\.|,)\d+)?" />
        </div>

        <div>
          6
          <input type="text" inputMode="phone" />
        </div>

        <div>
          7
          <input type="number" inputMode="phone" />
        </div>

        <div>
          8
          <input type="phone" inputMode="phone" />
        </div>

        <div>
          9
          <input type="text" inputMode="tel" />
        </div>

        <div>
          10
          <input type="number" inputMode="tel" />
        </div>

        <div>
          11
          <input type="tel" inputMode="tel" />
        </div>

        <button type="submit">aa</button>
      </form>

      <Keyboard
        layout={{ default: ["1 2 3 {bksp}", "4 5 6", "7 8 9", " 0 {enter}"] }}
        display={{ '{enter}': '↵'}}
        onKeyPress={(key) => {
          if(key == "{enter}")
            console.log("a");
        }}
        onChange={val => {
          console.log(val);
          // if (val == "{enter}")
          setValue(val);
        }}
      />
    </>
  );
}

export default App;
